//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OpenData from '@/views/fileStream/documentManage/weixin/openData'
// eslint-disable-next-line camelcase
import {
    delDocumentBill,
    documentBillAllPage,
    documentBillCountermand,
    documentBillExpurgate,
} from '@/api/modular/fileStream/documentManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { STable } from '@/components'
import { stateColor } from '@/mixins/index.js'
import { handleAppointEleHeight } from '@/utils/common.js'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
import { getDefaultColumns } from './config'
export default {
    components: {
        OpenData,
        STable,
    },
    mixins: [stateColor],
    data() {
        const formatDraftId = (id) => {
            if (!id) return []
            return JSON.parse(id)
        }
        return {
            btna: ['全部', '回收站'],
            btck: 0,
            selectedRowKeys: [],
            queryParam: {
                approvalTab: 1,
            },
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            flow_file_type: [],
            flow_approval_status: [],
            columns: [],
            func: documentBillAllPage,
            loadData: (parameter) => {
                const { acceptStatus } = parameter
                let params = { ...parameter }
                if (acceptStatus && acceptStatus.length > 0) params = { ...parameter, approvalStatus: acceptStatus[0] }
                delete params.acceptStatus
                return this.func(Object.assign(params, this.queryParam)).then((res) => {
                    const data = { ...res.data }
                    data.rows = data.rows.map((ele) => {
                        ele.draftId = formatDraftId(ele.draftId)
                        return ele
                    })
                    handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.big_title', '.s-table-tool'], 66, res)
                    return data
                })
            },
        }
    },
    created() {
        init_wx()
        this.getDict()
    },
    mounted() {
        handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.big_title', '.s-table-tool'])
    },
    methods: {
        restore({ id }) {
            documentBillCountermand([{ id }]).then((res) => {
                if (res.code === 200) {
                    this.$message.success('恢復成功')
                    this.$refs.table.refresh()
                }
            })
        },
        muitRestore() {
            documentBillCountermand(this.selectedRowKeys.map((j) => ({ id: j }))).then((res) => {
                if (res.code === 200) {
                    this.$message.success('批量恢復成功')
                    this.selectedRowKeys = []
                    this.$refs.table.refresh()
                }
            })
        },
        wipeOut({ id }) {
            documentBillExpurgate([{ id }]).then((res) => {
                if (res.code === 200) {
                    this.$message.success('徹底刪除成功')
                    this.$refs.table.refresh()
                }
            })
        },
        muitWipeOut() {
            documentBillExpurgate(this.selectedRowKeys.map((j) => ({ id: j }))).then((res) => {
                if (res.code === 200) {
                    this.$message.success('批量徹底刪除成功')
                    this.selectedRowKeys = []
                    this.$refs.table.refresh()
                }
            })
        },
        muitDelete() {
            delDocumentBill(this.selectedRowKeys.map((j) => ({ id: j }))).then((res) => {
                if (res.code === 200) {
                    this.$message.success('批量刪除成功')
                    this.selectedRowKeys = []
                    this.$refs.table.refresh()
                }
            })
        },
        singleDelete({ id }) {
            delDocumentBill([
                {
                    id,
                },
            ]).then((res) => {
                if (res.code === 200) {
                    this.$message.success('刪除成功')
                    this.$refs.table.refresh()
                }
            })
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys
        },
        foramtAcceptStatus(text) {
            switch (Number(text)) {
                case 0:
                    return '待接收'
                case 1:
                    return '已接收'
                default:
                    return ''
            }
        },
        change_status(i) {
            this.btck = i
            this.queryParam.approvalTab = i + 1
            this.columns = getDefaultColumns(this.flow_approval_status, i === 0)
            this.$refs.table.refresh()
        },
        onSearch(value) {
            this.$refs.table.refresh(true)
        },
        /**
         * 獲取字典數據
         */
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
                this.flow_file_type = res.data
            })
            sysDictTypeDropDown({ code: 'flow_approval_status' }).then((res) => {
                console.log('res.data+++++++++', res.data)
                this.flow_approval_status = res.data
                this.columns = getDefaultColumns(res.data)
            })
        },
        edit(record) {
            this.$router.push({ path: '/fileStreamDocumentManage', query: { id: record.id } })
        },
        senpi(record) {
            const { approvalId, nodeId, id } = record
            this.$router.push({ path: '/fileStream_Approval_Manage', query: { id, approvalId, nodeId } })
        },
        see(record) {
            let url = '/fileStream_Approval_process'
            // eslint-disable-next-line eqeqeq
            if (record.documentType == 2) {
                url = '/fileStreamDocumentSend'
            }
            console.log('url++++++++++++', url)
            this.$router.push({ path: url, query: { id: record.id, type: record.documentType } })
        },
        Filter(arr, s) {
            const values = arr.filter((item) => Number(item.code) === Number(s))
            if (values.length > 0) {
                return values[0].value
            }
        },
    },
}
